<template>
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-12">
            <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
               <div>
                  <h4 class="mb-3">로켓그로스 상품기획</h4>
                  <p class="mb-0"><br></p>
               </div>
            </div>
            <tab-nav :tabs="true" id="myTab-1">
               <tab-nav-items :active="true" id="planning-tab" ariaControls="planning" role="tab" :ariaSelected="true" title="상품기획" />
               <tab-nav-items :active="false" id="sourcing-tab" ariaControls="sourcing" role="tab" :ariaSelected="false" title="상품소싱" />
            </tab-nav>
                
            <tab-content id="myTabContent">
               <tab-content-item :active="true" id="planning" aria-labelled-by="planning-tab">
                  <div class="card">
                     <div class="card-header">
                        <button type="button" class="btn btn-primary mr-2 mt-1" @click="deleteitem">삭제</button>
                        <button type="button" class="btn btn-primary mr-2 mt-1" @click="search">검색</button>
                        <button type="button" class="btn btn-secondary mr-2 mt-1" @click="saveall">저장</button>
                     </div>
                     <div v-if="showspinner">
                        <img style="width:3%;" :src="require('../../../assets/images/small/loading.gif')">
                     </div>
                     <div class="card-body">
                        <div class="table-responsive mt-4" v-if="showplanning">
                           <tableList>          
                              <template v-slot:header >
                                 <tr class="ligth ligth-data">
                                    <th>
                                       <div class="checkbox d-inline-block">
                                          <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allchecked" @change="allcheck">
                                          <label for="checkbox1" class="mb-0"></label>
                                       </div>
                                    </th>
                                    <th style="font-size:90%;">수집일</th>
                                    <th style="font-size:90%;min-width:100px;">상품정보</th>
                                    <th style="font-size:90%;">키워드</th>
                                    <th style="font-size:90%;">키워드월매출</th>
                                    <th style="font-size:90%;">판매가</th>
                                    <th style="font-size:90%;">등록일</th>
                                    <th style="font-size:90%;">리뷰수/평점</th>
                                    <th style="font-size:90%;">구매조사지시</th>
                                 </tr>
                              </template>
                              <template v-slot:body >
                                 <tr role="row" v-for="a,i in products" :key="i">
                                    <td>
                                       <div class="checkbox d-inline-block">
                                          <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="a.checked">
                                          <label for="checkbox2" class="mb-0"></label>
                                       </div>
                                    </td>
                                    <td style="font-size:90%;">{{a.수집일}}</td>
                                    <td style="font-size:90%;min-width:100px;">
                                       <a :href="a.상품링크" target="_blank">
                                       <div class="d-flex align-items-center">
                                          <img :src="a.상품이미지" class="img-fluid rounded avatar-100 mr-3" alt="image">
                                          <div>
                                             <p class="mb-0"><small>{{a.상품명}}</small></p>
                                          </div>
                                       </div>
                                       </a>
                                    </td>
                                    <td style="font-size:90%;">{{a.키워드}}</td>
                                    <td style="font-size:90%;">{{a.키워드월매출.toLocaleString()}}</td>
                                    <td style="font-size:90%;">{{a.판매가.toLocaleString()}}</td>
                                    <td style="font-size:90%;">{{a.등록일}}</td>
                                    <td style="font-size:90%;">{{a.리뷰수}} / {{a.평점}}</td>
                                    <td style="font-size:90%;" class="text-center">
                                       <b-button :variant="purVar(a.구매조사지시)" class="mb-1 mr-2" style="font-size:90%" @click="confirmpurchase(a)">
                                          {{a.구매조사지시 ? '취소' : '구매조사'}}
                                       </b-button>
                                    </td>
                                 </tr>
                              </template>
                           </tableList>
                        </div>
                     </div>
                  </div>
               </tab-content-item>
               <tab-content-item :active="false" id="sourcing" aria-labelled-by="sourcing-tab">
                  <div class="card">
                     <div class="card-header">
                        <button type="button" class="btn btn-secondary mr-2 mt-1" @click="savesourcing">저장</button>
                     </div>
                     <div v-if="showspinner">
                        <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                     </div>
                     <div class="card-body">
                        <div class="table-responsive mt-4" v-if="sourcingproducts.length > 0">
                           <tableList>          
                              <template v-slot:header >
                                 <tr class="ligth ligth-data">
                                    <th style="font-size:90%;">수집일</th>
                                    <th style="font-size:90%;min-width:200px;">상품정보</th>
                                    <th style="font-size:90%;">키워드</th>
                                    <th style="font-size:90%;">키워드월매출</th>
                                    <th style="font-size:90%;">판매가</th>
                                    <th style="font-size:90%;">등록일</th>
                                    <th style="font-size:90%;">리뷰수/평점</th>
                                    <th style="font-size:90%;">구매조사지시</th>
                                    <th style="font-size:90%;">구매단가(CNY)</th>
                                    <th style="font-size:90%;">구매링크</th> 
                                    <th style="font-size:90%;">이미지링크</th> 
                                    <th style="font-size:90%;">추가링크지시</th>
                                    <th style="font-size:90%;">이미지작업지시</th>
                                    <th style="font-size:90%;">작업링크</th>
                                    <th style="font-size:90%;">상품등록지시</th>
                                    <th style="font-size:90%;">등록링크</th>
                                 </tr>
                              </template>
                              <template v-slot:body >
                                 <tr role="row" v-for="a,i in sourcingproducts" :key="i">
                                    <td style="font-size:90%;">{{a.수집일}}</td>
                                    <td style="font-size:90%;min-width:200px;">
                                       <a :href="a.상품링크" target="_blank">
                                       <div class="d-flex align-items-center">
                                          <img :src="a.상품이미지" class="img-fluid rounded avatar-100 mr-3" alt="image">
                                          <div>
                                             <p class="mb-0"><small>{{a.상품명}}</small></p>
                                          </div>
                                       </div>
                                       </a>
                                    </td>
                                    <td style="font-size:90%;">{{a.키워드}}</td>
                                    <td style="font-size:90%;">{{a.키워드월매출.toLocaleString()}}</td>
                                    <td style="font-size:90%;">{{a.판매가.toLocaleString()}}</td>
                                    <td style="font-size:90%;">{{a.등록일}}</td>
                                    <td style="font-size:90%;">{{a.리뷰수}} / {{a.평점}}</td>
                                    <td style="font-size:90%;" class="text-center">
                                       <b-button variant="outline-primary" class="mb-1 mr-2" style="font-size:90%" @click="confirmpurchase(a)">
                                          {{a.구매조사지시 ? '취소' : '구매조사'}}
                                       </b-button>
                                       <b-button v-if="!a.구매조사지시" variant="outline-secondary" class="mb-1 mr-2" style="font-size:90%" @click="deleteitem(i)">
                                          삭제
                                       </b-button>
                                    </td>
                                    <td style="font-size:90%;">
                                       <div v-if="a.구매조사지시">
                                          <input type="number" class="form-control" placeholder="구매단가" v-model="a.구매단가">
                                       </div>
                                    </td>
                                    <td style="font-size:90%;" class="text-center">
                                       <div v-if="a.구매조사지시">
                                          <input type="text" class="form-control" placeholder="구매링크" v-model="a.구매링크">
                                          <a :href="a.구매링크" target="_blank"><b-icon icon="link" scale="2" variant="secondary"></b-icon></a>
                                       </div>                                 
                                    </td>
                                    <td style="font-size:90%;">{{a.이미지링크}}</td>
                                    <td style="font-size:90%;">{{a.추가링크지시}}</td>
                                    <td style="font-size:90%;">{{a.이미지작업지시}}</td>
                                    <td style="font-size:90%;" class="text-center"><a :href="a.작업링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
                                    <td style="font-size:90%;">{{a.상품등록지시}}</td>
                                    <td style="font-size:90%;" class="text-center"><a :href="a.등록링크" target="_blank"><b-icon icon="link" scale="2" variant="secondary"></b-icon></a></td>
                                 </tr>
                              </template>
                           </tableList>
                        </div>
                     </div>
                  </div>
               </tab-content-item>
            </tab-content>
         </div>
      </div>
   </div>
</template>
<script>
import axios from 'axios';
import { core } from '../../../config/pluginInit'
export default {
   name:'DataTable',
   mounted () {
   },
   data(){
      return{
         showplanning:false,
         products: [],
         productsinit: [],
         showlist:false,
         showspinner :false,
         allchecked:false,
         sourcingproducts:[],
      }
   },
   methods: {
      deleteitem(){
         if(this.products.filter(e => e.checked).length > 0){
            if(confirm(this.products.filter(e => e.checked).length + '개의 상품을 삭제하시겠습니까?')){
               this.showplanning = false;
               this.showspinner = true;
               axios.post('/api/rocketgrowth/planning/deleteitem',{arr:this.products.filter(e => e.checked)})
               .then( result => {
                  if(result.data.요청결과 == 'success'){
                     alert('삭제완료')
                     var arr = [];
                     this.productsinit = [];
                     this.products.forEach(e => {
                        if(!e.checked){
                           arr.push(this.deepClone(e))
                           this.productsinit.push(this.deepClone(e))
                        }
                     })
                     this.products = arr;
                     this.showplanning=true;
                     this.showspinner=false;
                  }
               }).catch((e)=>{
                  alert('요청 실패(205)')
                  console.log(e);
                  return true
               })
            }
         } else {
            if(confirm('모든 상품을 삭제하시겠습니까?')){
               this.showplanning = false;
               this.showspinner = true;
               axios.post('/api/rocketgrowth/planning/deleteitem',{arr:this.products})
               .then( result => {
                  if(result.data.요청결과 == 'success'){
                     this.products= [];
                     alert('삭제완료')
                     this.showplanning=true;
                     this.showspinner=false;
                  }
               }).catch((e)=>{
                  alert('요청 실패(227)')
                  console.log(e);
                  return true
               })
            }
         }
      }, 
      savesourcing(){
         this.showplanning = false;
         this.showspinner = true;
         var arr = [];
         var obj = {};
         this.sourcingproducts.forEach(e => {
            obj = {
               companyid : this.$store.state.user.companyid,
               수집일 : e.수집일,
               상품id : e.상품id,
               상품링크 : e.상품링크,
               상품이미지 : e.상품이미지,
               상품명 : e.상품명,
               키워드 : e.키워드,
               키워드월매출 : e.키워드월매출,
               판매가 : e.판매가,
               등록일 : e.등록일,
               리뷰수 : e.리뷰수,
               평점 : e.평점,

               구매조사지시 : e.구매조사지시,
               구매단가 : e.구매단가,
               구매링크 : e.구매링크,
               이미지링크 : e.이미지링크,
               추가링크지시 : e.추가링크지시,
               이미지작업지시 : e.이미지작업지시,
               작업링크 : e.작업링크,
               상품등록지시 : e.상품등록지시,
               등록링크 : e.등록링크,
            }
            arr.push(obj)
         })
         axios.post('/api/rocketgrowth/sourcing/saveitem',arr)
         .then( result => {
            if(result.data.요청결과 == 'success'){
               alert('저장완료')
               this.showplanning=true;
               this.showspinner=false;
            }
         }).catch((e)=>{
            alert('요청 실패(275)')
            console.log(e);
         })
      },
      purVar(foo){
         if(!foo){
            return "outline-primary"
         } else {
            return "outline-secondary"
         }
      }, 
      allcheck(){
         if(this.allchecked){
            this.products.forEach(e => e.checked = true)
         } else {
            this.products.forEach(e => e.checked = false)
         }
      },
      saveall(){
         alert('아직 기능 미완성')
      },
      confirmpurchase(a){
         if(!a.구매조사지시){
            a.구매조사지시 = true;
            this.sourcingproducts.push(a);
         } else {
            a.구매조사지시 = false;
         }
      },
      async search(){
         this.showspinner = true;
         this.products = [];
         this.showlist = false;
         await axios.post('/api/analysis/getproducts',{})
         .then( result => {
         if (result.data.요청결과 == 'success') {
            var ar = result.data.result;
            console.log(ar);
            // var obj = {};
            // var arr = [];
            ar.forEach(e => {
               // if(!arr.find(ele => ele.상품id === e.product.id)){  
               //    obj = {
               //       checked: false,
               //       _id : e._id,
               //       수집일 : e.date,
               //       상품id : e.product.id,
               //       상품링크 : e.product.productUrl,
               //       상품이미지 : e.product.galleryImages[0].url,
               //       상품명 : e.product.name,
               //       키워드 : e.keyword,
               //       키워드월매출 : parseInt(e.longtermsales),
               //       판매가 : e.product.discountedSalePrice ? e.product.discountedSalePrice : e.product.salePrice,
               //       등록일 : e.product.regDate.slice(0,10),
               //       리뷰수 : e.product.reviewAmount.totalReviewCount,
               //       평점 : e.product.reviewAmount.averageReviewScore,

               //       구매조사지시 : false,
               //       구매단가 : '',
               //       구매링크 : '',
               //       이미지링크 : '',
               //       추가링크지시 : false,
               //       이미지작업지시 : false,
               //       작업링크 : '',
               //       상품등록지시 : false,
               //       등록링크 : '',
               //    }
               //    arr.push(this.deepClone(obj))
               //    this.products.push(this.deepClone(obj));
               //    this.productsinit.push(this.deepClone(obj));
               // }
                  this.products.push(this.deepClone(e));
                  this.productsinit.push(this.deepClone(e));
            })
            this.showplanning=true;
            this.showspinner=false;
         }
         }).catch((e)=>{
               alert('요청 실패(90)')
               console.log(e);
               return true
         })
      },
      deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
   },
   destroyed(){
      core.index()
   }
}
</script>